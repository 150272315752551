import React, { useState } from 'react';
import { connect } from 'react-redux';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { addTask } from '../../actions';


const NewTask = ({ projects, addTaskAction }) => {
  const [name, setName] = useState('');
  const [project, setProject] = useState('');
  const [subproject, setSubproject] = useState('');
  const [subprojectList, setSubprojectList] = useState([]);
  const handleChangeProject = (e) => {
    const proj = projects.find((p) => p.key === e.target.value) || { key: '', subprojects: [] };

    setProject(proj.key);
    setSubprojectList(proj.subprojects);
    setSubproject('');
  };
  const handleChangeSubproject = (e) => {
    setSubproject(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const task = { name, project, subproject, done: false };
    addTaskAction({ task });
    setName('');
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="new-task-form">
      <div className="select-project-container">
        <div className="select-caret">
          <select autoFocus onChange={handleChangeProject} value={project} className="new-task-select">
            <option value="">Project</option>
            {projects.map((p) => (
              <option value={p.key}>{p.name}</option>
            ))}
          </select>
          <MdKeyboardArrowDown />
        </div>
        <div className="select-caret">
          <select onChange={handleChangeSubproject} value={subproject} className="new-task-select">
            <option value="">Sub project</option>
            {subprojectList.map((s) => (
              <option>{s}</option>
            ))}
          </select>
          <MdKeyboardArrowDown />
        </div>
      </div>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="new-task-text"
        placeholder="New task..."
      />
    </form>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects.list,
});

const mapDispatchToProps = {
  addTaskAction: addTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTask);
