import firebase from 'firebase';
import {
  LOAD_CURRENT_TASK,
  LOAD_TASKS_LIST,
  UPDATE_CURRENT_TASK,
  LOAD_TODAY_TRACKED_TASKS,
} from './types';

export const loadCurrentTask = () => (
  (dispatch) => {
    const db = firebase.firestore();
    db.collection('tracking').doc('currentTask')
      .onSnapshot((snapshot) => {
        dispatch({ type: LOAD_CURRENT_TASK, payload: { currentTask: snapshot.data() } });
      });
  }
);

export const updateCurrentTask = (currentTask) => (
  (dispatch) => {
    const db = firebase.firestore();
    db.collection('tracking').doc('currentTask').set(currentTask)
      .then(() => {
        dispatch({ type: UPDATE_CURRENT_TASK, payload: { currentTask } });
      });
  }
);

export const loadTasks = () => (
  (dispatch) => {
    const db = firebase.firestore();
    db.collection('tasks').where('done', '==', false)
      .onSnapshot((snapshot) => {
        const tasks = snapshot.docs.map((snap) => ({ ...snap.data(), key: snap.id }));
        dispatch({ type: LOAD_TASKS_LIST, payload: { tasks } });
      });
  }
);

export const addTask = ({ task }) => (
  () => {
    const db = firebase.firestore();
    db.collection('tasks').add({
      ...task,
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });
  }
);

export const toggleDone = ({ task }) => (
  () => {
    const db = firebase.firestore();
    db.collection('tasks').doc(task.key).update({ done: !task.done });
  }
);

export const trackTask = () => (
  (dipatch, getStore) => {
    const db = firebase.firestore();
    const { currentYear, currentWeek, weekday, blockDuration } = getStore().app.config;
    const task = {
      ...getStore().tasks.currentTask,
      year: currentYear,
      week: currentWeek,
      weekday,
      blockDuration,
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    db.collection('tracking').doc('tasks').collection('blocks').add(task);
  }
);

export const loadTrackedTasks = () => (
  (dispatch, getStore) => {
    const db = firebase.firestore();
    const { currentYear, currentWeek } = getStore().app.config;
    db.collection('tracking').doc('tasks').collection('blocks')
      .where('year', '==', currentYear)
      .where('week', '==', currentWeek)
      // .orderBy('createdAt', 'desc') // not working since indexes are missing
      .onSnapshot((snapshot) => {
        const tasks = snapshot.docs.map((snap) => ({ ...snap.data(), key: snap.id }))
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
        dispatch({ type: LOAD_TODAY_TRACKED_TASKS, payload: { tasks } });
      });
  }
);
