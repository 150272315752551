import React from 'react';
import { connect } from 'react-redux';
import { Button, CloseButton } from '../common';
import TimelineProgress from './TimelineProgress';
import { trackCEODaily } from '../../actions';

const CEOWeek = ({ trackCEODailyAction }) => {
  const handleTrack = () => trackCEODailyAction({ notes: '' });

  return (
    <div style={{ maxWidth: 900, margin: '0 auto' }}>
      <CloseButton />
      <TimelineProgress />
      <h2>W15</h2>
      <ol style={{ fontSize: 21 }}>
        <li>
          Implement GPD
          <ul>
            <li>
              Conquer Morning (07:00)
              <ul><li><i style={{ color: '#999' }}>(alarm off -> water -> c4 -> exerc -> cShower -> breakfast + bible -> pray -> topic)</i></li></ul>
            </li>
            <li>Achiever: 100% daily high execution</li>
            <li>CEO: Honor CEO Mode</li>
          </ul>
        </li>
        <li>
          Get Purpose Done (15/w)
          <ol>
            <li>Write course content</li>
          </ol>
        </li>
        <li>
          CreatorMobile
          <ul>
            <li>Agency machine (5)</li>
            <li>
              Define nitche (5)
              <ul>
                <li>Design MVP for NextP, Restau & Teach =&gt; Choose</li>
              </ul>
            </li>
            <li>
              Proposals (30/week)
              <ul>
                <li>Define MVP (2)</li>
                <li>Get ready for launch THIS WEEK (28)</li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <Button title="Finish" onClick={handleTrack} linkTo="/" />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  trackCEODailyAction: trackCEODaily,
};

export default connect(null, mapDispatchToProps)(CEOWeek);
