import React from 'react';

export default () => (
  <div>
    <div style={{ fontWeight: 'bold', marginBottom: 5 }}>Establecer el Reino de los Cielos en mi hogar</div>
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 9, height: 8 }} />

    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#fff', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 20, borderRadius: 4, backgroundColor: 'green', margin: 1.4, fontSize: 10, textAlign: 'center', overflow: 'scroll' }}>23</div>
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 9, height: 8 }} />

    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 9, height: 8 }} />

    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
    <div style={{ display: 'inline-block', width: 14, height: 8, borderRadius: 4, backgroundColor: '#4d5363', margin: 1.4 }} />
  </div>
)
