import React from 'react';
import { Button, CloseButton } from '../common';
import TimelineProgress from './TimelineProgress';

const CEOQuarter = () => (
  <div style={{ maxWidth: 900, margin: '0 auto' }}>
    <CloseButton />
    <TimelineProgress />
    <h2>Q2</h2>

    <ol style={{ fontSize: 21 }}>
      <li>
        Implement a solid lifestyle system (GPD)
        <ul>
          <li>Implement a solid lifestyle system</li>
          <li>Technology that covers the core of the system</li>
          <li>Get my life totally guided by the system. No actions that are not conceived in the system</li>
          <li>13 written weekly topics</li>
          <li><i style={{ textDecoration: 'line-through', color: '#555' }}>1 page doc that clearly explains the whole process</i></li>
        </ul>
      </li>
      <li>
        Build our home
        <ul>
          <li>Define all house structure's details</li>
          <li>Strict finances sheet</li>
          <li>3-5 providers for each fine work</li>
        </ul>
      </li>
      <li>
        Share my message to the world (Impact Valley)
        <ul>
          <li>Master communication & presentation skills</li>
          <li>Define and create my message content & experience (ideas, examples, videos, photos, presentation, ...)</li>
          <li>Branding</li>
          <li>Landing page</li>
          <li>Online courses</li>
          <li>Request universities, schools, companies, events and organizations for their stage to talk</li>
          <li>Inbound content on social media (define voice and graphic line, define schedule and guidelines)</li>
          <li>Ads</li>
          <li><i style={{ textDecoration: 'line-through', color: '#555' }}>Lean canvas</i></li>
        </ul>
      </li>
      <li>
        CreatorMobile finish all current projects
        <ul>
          <li>Review every project and create a detailed plan for each.</li>
          <li>Finish all projects.</li>
          <li>Restore contact with the team and assume responsibilities.</li>
          <li>Restore contact with clients, give them extra value somehow.</li>
          <li>Decide if we are moving forward with the agency</li>
          <li>Define a working process to apply strictly (me and the team)</li>
          <li>Finances</li>
        </ul>
      </li>
      <li>
        Startup:
        <ul>
          <li>GPD. Think how can we launch it. It should be mixed to Impact Valley</li>
          <li>Define a niche for app as a service</li>
          <li>Launch MVP</li>
          <li>Define marketing strategy</li>
          <li>Reach a 50+ leads</li>
        </ul>
      </li>
      <li>
        Other:
        <ul>
          <li>DRK, re launch courses</li>
          <li>DRK, Go online</li>
          <li>Organize this notion parent page</li>
        </ul>
      </li>
    </ol>

    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <Button linkTo="/ceo/week" title="Continue" />
    </div>
  </div>
);

export default CEOQuarter;
