import firebase from 'firebase';
import {
  LOAD_CEO_DAILY_TRACKING,
} from './types';

export const loadCEODailyTracking = () => (
  (dispatch, getStore) => {
    const db = firebase.firestore();
    const { config: { currentYear, currentWeek, weekday } } = getStore().app;
    db.collection('tracking').doc('CEO').collection(`${currentYear}w${currentWeek}`).doc(weekday.toString())
      .onSnapshot((snapshot) => {
        dispatch({ type: LOAD_CEO_DAILY_TRACKING, payload: { tracking: snapshot.data() } });
      });
  }
);

export const trackCEODaily = ({ notes }) => (dispatch, getStore) => {
  const db = firebase.firestore();
  const { config: { currentYear, currentWeek, weekday } } = getStore().app;
  db.collection('tracking').doc('CEO').collection(`${currentYear}w${currentWeek}`).doc(weekday.toString())
    .set({ notes });
};
