import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Sound from 'react-sound';
import { FaPlay } from 'react-icons/fa';
import { Button } from '../common';
import { setRemainingTime, togglePlay, trackTask } from '../../actions';
import TickSound from '../../assets/sounds/tick.mp3';
import BirdSound from '../../assets/sounds/bird.mp3';
import AlarmSound from '../../assets/sounds/alarm.mp3';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Timer = ({
  playing,
  remainingTime,
  blockDuration,
  setRemainingTimeAction,
  togglePlayAction,
  trackTaskAction,
}) => {
  const [delay, setDelay] = useState(null);
  // const [count, setCount] = useState(blockDuration);
  const [playSound, setPlaySound] = useState(null);
  const [finished, setFinished] = useState(false);
  const minutes = parseInt(remainingTime / 60, 10);
  const seconds = parseInt(remainingTime % 60, 10);
  const onTogglePlay = () => {
    setDelay(!playing ? 1000 : null);
    togglePlayAction();
  };
  const handleTrackTimer = () => {
    trackTaskAction();
    resetTimer();
  };
  const resetTimer = () => {
    setRemainingTimeAction(blockDuration);
    setFinished(false);
    setPlaySound(null);
  };
  const timeString = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  const color = playing ? 'white' : '#61dafb';

  document.title = timeString;

  useInterval(() => {
    setPlaySound(null);

    if (remainingTime === 1) { // Play alarm sound and stop timer
      setDelay(null);
      setPlaySound(AlarmSound);
      setFinished(true);
      togglePlayAction();
    } else if (minutes % 10 === 0 && seconds === 1) { // Play 'bird' every 10 mins
      setPlaySound(BirdSound);
    } else if (seconds === 1) { // Play 'tick' every minute
      setPlaySound(TickSound);
    }

    setRemainingTimeAction(remainingTime - 1);
  }, delay);

  return (
    <>
      <CircularProgressbarWithChildren
        value={blockDuration - remainingTime}
        maxValue={blockDuration}
        strokeWidth={5}
        styles={buildStyles({
          pathColor: color,
          trailColor: '#4d5363',
        })}
      >
        <div className="countdown-container">
          {!finished ? (
            <div onClick={onTogglePlay} style={{ textAlign: 'center', color }}>
              {!playing && (
                <div style={{ fontSize: 90, marginTop: 30, marginLeft: 20 }}>
                  <FaPlay />
                </div>
              )}
              <div style={{ fontSize: playing ? 60 : 21 }}>
                {timeString}
              </div>
            </div>
          ) : (
            <div>
              <Button title="Track time block" onClick={handleTrackTimer} style={{ marginTop: 100 }} />
              <div className="link" style={{ marginTop: 50, textAlign: 'center' }} onClick={resetTimer}>Ignore</div>
            </div>
          )}
        </div>
      </CircularProgressbarWithChildren>

      <Sound
        url={playSound}
        playStatus={Sound.status.PLAYING}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  playing: state.timer.playing,
  remainingTime: state.timer.remainingTime,
  blockDuration: state.timer.blockDuration,
});

const mapDispatchToProps = {
  setRemainingTimeAction: setRemainingTime,
  togglePlayAction: togglePlay,
  trackTaskAction: trackTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
