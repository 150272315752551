import {
  TIMER_SET_REMAINING_TIME,
  TIMER_TOGGLE_PLAY,
} from './types';


export const setRemainingTime = (time) => ({
  type: TIMER_SET_REMAINING_TIME,
  payload: { time },
});

export const togglePlay = () => ({
  type: TIMER_TOGGLE_PLAY,
});
