import React from 'react';
import { connect } from 'react-redux';

const TaskProjectName = ({ task, style, projects, ignoreSubProject = false }) => {
  const project = projects.find((p) => p.key === task.project);

  return (
    <>
      { project ? (
        <span style={{ ...style }}>
          {project.name}
          {!ignoreSubProject && (
            <>
              <span style={{ margin: '0 15px' }}>/</span>
              {task.subproject}
            </>
          )}
        </span>
      ) : <span style={{ ...style }}>[ ]</span> }
    </>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects.list,
});

export default connect(mapStateToProps)(TaskProjectName);
