import React from 'react';
import { connect } from 'react-redux';
import { isNil, forEach, orderBy } from 'lodash';
import { Bullet } from '../common';
import TaskProjectName from '../projects/TaskProjectName';

const TasksTracking = ({ tasksWeek }) => {
  const groupedByProject = {};
  const groupedBySubproject = {};
  tasksWeek.map((task) => {
    const { project, subproject } = task;
    const key = `${project}/${subproject}`;

    // Order by project
    if (isNil(groupedByProject[project])) {
      groupedByProject[project] = { project, subproject, count: 1 };
    } else {
      groupedByProject[project].count += 1;
    }

    // Order by project/subproject
    if (isNil(groupedBySubproject[key])) {
      groupedBySubproject[key] = { project, subproject, count: 1 };
    } else {
      groupedBySubproject[key].count += 1;
    }
    return null;
  });

  const renderTasksResume = (task, ignoreSubProject = false) => (
    <div key={task.key}>
      <div style={{ width: 15, textAlign: 'right', display: 'inline-block', verticalAlign: 'super' }}>
        {task.count}
      </div>
      <Bullet active margin="5px 10px" />
      <TaskProjectName task={task} style={{ verticalAlign: 'super' }} ignoreSubProject={ignoreSubProject} />
    </div>
  );

  const projectsArray = [];
  forEach(groupedByProject, (task) => projectsArray.push(task));

  const subprojectsArray = [];
  forEach(groupedBySubproject, (task) => subprojectsArray.push(task));

  return (
    <div style={{ textAlign: 'left', display: 'inline-block' }}>
      {orderBy(projectsArray, ['count'], 'desc').map((task) => renderTasksResume(task, true)) }
      <div style={{ marginTop: 20, marginBottom: 20, height: 1, backgroundColor: '#41454e' }} />
      {orderBy(subprojectsArray, ['count'], 'desc').map((task) => renderTasksResume(task)) }
    </div>
  );
};

const mapStateToProps = (state) => ({
  tasksWeek: state.tasks.doneWeek || [],
});

export default connect(mapStateToProps)(TasksTracking);
