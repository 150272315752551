import firebase from 'firebase';
import {
  LOAD_PROJECTS,
} from './types';

export const loadProjects = () => (
  (dispatch) => {
    const db = firebase.firestore();
    db.collection('projects').get()
      .then((snapshot) => {
        const projects = snapshot.docs.map((snap) => ({ ...snap.data(), key: snap.id }));
        dispatch({ type: LOAD_PROJECTS, payload: { projects } });
      });
  }
);

export const updateProject = () => {};
