import React from 'react';
import { Button, CloseButton } from '../common';

const CEOLetter = () => (
  <div>
    <CloseButton />
    <div style={{ maxWidth: 900, margin: '30px auto', fontSize: 21 }}>
      Hey J33! This is J40 reaching you from the future. We just turned 40 this week, and I am excited to share with you about how things are going on now. You'll be excited to hear!<br /><br />

      First of all, and most important: <strong>our relationship with God is strong and beautiful.</strong> Throughout these years He showed us <strong><i>His Endless Love</i></strong>. He opened huge doors and made the impossible happen. We, the whole family, are deep in love with our Dad.<br /><br />

      Family.  Family. Family is our most beautiful gift. Our wife, she is shining, full of life, full of joy, full of hope, full of love. We enjoy, in Christ, a deep an open connection, like never before. Majo & Fer, they are these beautiful and full passion little girls, full of life and joy, full of HIS Spirt. They will be 10 & 8 in a few months. We are doing a great job with them. We are an inspiration for them, an example about trusting God, about walking in faith and obedience, <strong>no matter what</strong>.<br /><br />

      We are in our best shape ever. We have a healthy, moving and exciting lifestyle. We are full of energy and focus all day long. Living the present with lot of enthusiasm.<br /><br />

      Our business and leadership is making a huge impact among generations, people's lives are being transformed. We employee the best talent around the world and work with great clients.<br /><br />

      I know that right now, as J33, you are struggling with some longtime battles. I know that right now you can't find the way out to this frustrating journey. Don't worry, we eventually figured it out, and things just flew out, like a bamboo tree! Your faith & hope was key in this process, starting over and over again with hope and passion, moving forward even through lack of clarity and capabilities, silencing the "I am not good enough" voices and moving as a Solider. Good job <strong>GLADIATOR!</strong> See you in a few years!<br /><br />
    </div>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <Button linkTo="/ceo/year" title="Continue" />
    </div>
  </div>
);

export default CEOLetter;
