import React from 'react';
import { useHistory } from 'react-router-dom';

export default ({ title, onClick, linkTo, style }) => {
  const history = useHistory();
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
    if (linkTo) {
      history.push(linkTo);
    }
  };

  return (
    <div
      onClick={onClickHandler}
      style={{ padding: '10px 20px', border: '1px solid #fff', borderRadius: 4, cursor: 'pointer', display: 'inline-block', marginTop: 20, marginBottom: 20, ...style }}>
      {title}
    </div>
  );
};
