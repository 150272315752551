import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ value, title, text, pathColor, containerStyle, titleStyle }) => (
  <div style={{ display: 'inline-block', width: 130, margin: '0 15px', ...containerStyle || {} }}>
    <div style={{ fontSize: 16, marginBottom: 10, textAlign: 'center', ...titleStyle || {} }}>
      {title}
    </div>
    <CircularProgressbar
      value={value}
      strokeWidth={5}
      styles={buildStyles({
        pathColor: pathColor || 'green',
        trailColor: '#4d5363',
        textColor: '#fff',
      })}
      text={text}
    />
  </div>
);

export default CircularProgress;
