import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { FaCheck } from 'react-icons/fa';
import { trackWeeklyDaily } from '../../actions';

const TrackWeekly = ({ tracking, time, weekday, trackWeeklyDailyAction }) => {
  const handleTrack = () => trackWeeklyDailyAction({ weekday, time });

  return (
    <div style={{ display: 'inline-block', margin: 10 }}>
      {!isEmpty(tracking) && !isEmpty(tracking[weekday]) && tracking[weekday][time] ? (
        <div
          style={{ width: 90, height: 90, borderRadius: 45, backgroundColor: 'green', textAlign: 'center' }}
        >
          <span style={{ display: 'inline-block', paddingTop: 33 }}><FaCheck /></span>
        </div>
      ) : (
        <div
          onClick={handleTrack}
          style={{ width: 90, height: 90, borderRadius: 45, backgroundColor: '#777', textAlign: 'center', cursor: 'pointer' }}
        >
          <span style={{ display: 'inline-block', paddingTop: 33 }}>{time.substring(0, 9)}</span>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  trackWeeklyDailyAction: trackWeeklyDaily,
};

export default connect(null, mapDispatchToProps)(TrackWeekly);
