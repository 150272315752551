import { DateTime } from 'luxon';
import {
  LOAD_CONFIG,
  UPDATE_CONFIG,
} from '../actions/types';

const INITIAL_STATE = {
  config: {},
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case LOAD_CONFIG:
      return { ...state, config: { ...payload.config, weekday: DateTime.local().minus({ hours: 5 }).weekday } };
    case UPDATE_CONFIG:
      return { ...state, config: { ...state.config, ...payload.config } };
    default:
      return state;
  }
};
