export const LOAD_CURRENT_TASK = 'LOAD_CURRENT_TASK';
export const UPDATE_CURRENT_TASK = 'UPDATE_CURRENT_TASK';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const LOAD_TASKS_LIST = 'LOAD_TASKS_LIST';
export const LOAD_CURRENT_WEEK = 'LOAD_CURRENT_WEEK';
export const LOAD_CONFIG = 'LOAD_CONFIG';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const LOAD_WEEKLY_DAILY_TRACKING = 'LOAD_WEEKLY_DAILY_TRACKING';
export const LOAD_CEO_DAILY_TRACKING = 'LOAD_CEO_DAILY_TRACKING';
export const LOAD_TODAY_TRACKED_TASKS = 'LOAD_TODAY_TRACKED_TASKS';

export const TIMER_SET_REMAINING_TIME = 'TIMER_SET_REMAINING_TIME';
export const TIMER_TOGGLE_PLAY = 'TIMER_TOGGLE_PLAY';
