import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { CloseButton } from '../common';
import { loadCurrentWeek, loadConfig, loadWeeklyDailyTracking } from '../../actions';
import TrackWeekly from './TrackWeekly';

const TasksScreen = ({ focus, body, loadCurrentWeekAction, tracking, loadConfigAction, config, loadWeeklyDailyTrackingAction }) => {
  const weekday = DateTime.local().minus({ hours: 5 }).weekday.toString();
  useEffect(() => {
    // loadConfigAction();
  }, []);
  useEffect(() => {
    loadCurrentWeekAction();
    loadWeeklyDailyTrackingAction({ weekday });
  }, [config]);

  return (
    <div className="weekly-focus-container">
      <CloseButton />
      <h2>{focus}</h2>
      <p className="body1" dangerouslySetInnerHTML={{ __html: body }} />

      <TrackWeekly time="morning" weekday={weekday} tracking={tracking} />
      <TrackWeekly time="noon" weekday={weekday} tracking={tracking} />
      <TrackWeekly time="night" weekday={weekday} tracking={tracking} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  config: state.app.config,
  focus: state.weekly.current.focus,
  body: state.weekly.current.body,
  tracking: state.weekly.tracking,
});

const mapDispatchToProps = {
  loadCurrentWeekAction: loadCurrentWeek,
  loadConfigAction: loadConfig,
  loadWeeklyDailyTrackingAction: loadWeeklyDailyTracking,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksScreen);
