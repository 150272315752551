import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CloseButton } from '../common';
import TaskProjectName from '../projects/TaskProjectName';
import Timer from './Timer';
import {
  loadCurrentTask,
  loadConfig,
  loadProjects,
} from '../../actions';

const FocusMode = ({
  currentTask,
  loadCurrentTaskAction,
  loadProjectsAction,
  loadConfigAction,
}) => {
  useEffect(() => {
    loadConfigAction();
    loadCurrentTaskAction();
    loadProjectsAction();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
      <CloseButton />
      <div style={{ flexGrow: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <TaskProjectName task={currentTask} style={{ margin: 0, color: '#ccc' }} />
        <p className="current-task">{currentTask.name}</p>
        <p className="current-task-desc">{currentTask.description}</p>
      </div>

      <div style={{ margin: '0 auto' }}>
        <Timer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  config: state.app.config,
  currentTask: state.tasks.currentTask,
});

const mapDispatchToProps = {
  loadCurrentTaskAction: loadCurrentTask,
  loadProjectsAction: loadProjects,
  loadConfigAction: loadConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(FocusMode);
