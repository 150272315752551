import {
  LOAD_CURRENT_TASK,
  LOAD_TASKS_LIST,
  LOAD_TODAY_TRACKED_TASKS,
} from '../actions/types';

const INITIAL_STATE = {
  list: [],
  currentTask: {},
  doneWeek: [],
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case LOAD_CURRENT_TASK:
      return { ...state, currentTask: payload.currentTask };
    case LOAD_TASKS_LIST:
      return { ...state, list: payload.tasks };
    case LOAD_TODAY_TRACKED_TASKS:
      return { ...state, doneWeek: payload.tasks };
    default:
      return state;
  }
};
