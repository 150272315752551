import {
  TIMER_SET_REMAINING_TIME,
  TIMER_TOGGLE_PLAY,
  LOAD_CONFIG,
} from '../actions/types';

const INITIAL_STATE = {
  playing: false,
  remainingTime: 25 * 60,
  blockDuration: 25 * 60,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case TIMER_SET_REMAINING_TIME:
      return { ...state, remainingTime: payload.time };
    case TIMER_TOGGLE_PLAY:
      return { ...state, playing: !state.playing };
    case LOAD_CONFIG:
      return {
        ...state,
        blockDuration: payload.config.blockDuration * 60,
        remainingTime: state.blockDuration === state.remainingTime
          ? payload.config.blockDuration * 60 : state.remainingTime,
      };
    default:
      return state;
  }
};
