import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, CloseButton } from '../common';
import { updateAppConfig } from '../../actions';

const ConfigScreen = ({ configData, updateAppConfigAction }) => {
  const [config, setConfig] = useState(configData);

  const updateValue = (key, value, type) => {
    const configBuffer = { ...config };
    configBuffer[key] = type === 'number' ? parseInt(value, 10) : value;
    setConfig(configBuffer);
  };

  return (
    <div>
      <CloseButton />
      <div style={{ maxWidth: 900, margin: '30px auto', fontSize: 21 }}>
        <h2>Config</h2>
        {Object.keys(config).map((key) => (
          <div style={{ marginBottom: 20 }}>
            <label style={{ marginRight: 15 }}>{key}</label>
            <input
              type={typeof config[key] === 'number' ? 'number' : 'text'}
              value={config[key]}
              onChange={(e) => updateValue(key, e.target.value, typeof config[key])}
            />
          </div>
        ))}

        <Button onClick={() => updateAppConfigAction(config)} title="Update" linkTo="/" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  configData: state.app.config,
});

const mapDispatchToProps = {
  updateAppConfigAction: updateAppConfig,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigScreen);
