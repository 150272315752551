import firebase from 'firebase';
import {
  LOAD_CONFIG,
  UPDATE_CONFIG,
} from './types';

export const loadConfig = () => (
  (dispatch) => {
    const db = firebase.firestore();
    db.collection('app').doc('config').get()
      .then((snapshot) => {
        dispatch({ type: LOAD_CONFIG, payload: { config: snapshot.data() } });
      });
  }
);

// Internal Config, used for UI states, like changing the week locally without affecting the DB
export const updateLocalConfig = (config) => ({
  type: UPDATE_CONFIG,
  payload: { config: { ...config } },
});

export const updateAppConfig = (config) => (
  () => {
    const db = firebase.firestore();
    db.collection('app').doc('config').set(config);
  }
);
