import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateCurrentTask, toggleDone } from '../../actions';

const TasksList = ({ tasks, updateCurrentTaskAction, toggleDoneAction }) => {
  const history = useHistory();

  return (
    <div className="tasks-list-container">
      {tasks.map((task) => (
        <div className="task-item" key={task.key}>
          <div
            onClick={() => { updateCurrentTaskAction(task); history.push('/'); }}
            className="task-set-current link"
          >
            DO
          </div>
          <input
            type="checkbox"
            className="task-checkbox"
            onClick={() => toggleDoneAction({ task })}
          />
          {task.name}
        </div>
      ))}
    </div>
  );
}

const mapDispatchToProps = {
  updateCurrentTaskAction: updateCurrentTask,
  toggleDoneAction: toggleDone,
};

export default connect(null, mapDispatchToProps)(TasksList);
