import React from 'react';
import { Button, CloseButton } from '../common';

const CEOStart = () => (
  <div>
    <CloseButton />
    <div style={{ textAlign: 'center', fontSize: 80, marginTop: 150, fontWeight: 'bold' }}>2020</div>
    <div style={{ textAlign: 'center', fontSize: 30, marginTop: 50, fontWeight: 'bold' }}>
      Establecer el Reino de los Cielos en mi hogar
    </div>
    <div style={{ textAlign: 'center', marginTop: 50 }}>
      <Button linkTo="/ceo/letter" title="Start" />
    </div>
  </div>
);

export default CEOStart;
