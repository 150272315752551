import React from 'react';
import { connect } from 'react-redux';
import { Bullet } from '../common';
import TaskProjectName from '../projects/TaskProjectName';

const TASKS_GOAL = 10;

const TasksTracking = ({ tasksWeek, weekday }) => {
  const tasks = tasksWeek.filter((t) => t.weekday === weekday);
  const renderMissingTasks = () => {
    if (tasks.length >= TASKS_GOAL) {
      return null;
    }
    const bullets = [];
    for (let i = 0; i < TASKS_GOAL - tasks.length; i += 1) {
      bullets.push(<div key={i}><Bullet active={false} margin="5px 10px 5px 25px" /></div>);
    }
    return bullets;
  };
  const renderDoneTask = (task, i) => (
    <div key={task.key}>
      <div style={{ width: 15, textAlign: 'right', display: 'inline-block', verticalAlign: 'super' }}>
        {i === 0 && tasks.length}
      </div>
      <Bullet active margin="5px 10px" />
      <TaskProjectName task={task} style={{ verticalAlign: 'super' }} />
    </div>
  );

  return (
    <div style={{ textAlign: 'left', display: 'inline-block' }}>
      {renderMissingTasks()}
      {tasks.map((task, i) => renderDoneTask(task, i)) }
    </div>
  );
};

const mapStateToProps = (state) => ({
  tasksWeek: state.tasks.doneWeek || [],
  weekday: state.app.config.weekday,
});

export default connect(mapStateToProps)(TasksTracking);
