import {
  LOAD_PROJECTS,
} from '../actions/types';

const INITIAL_STATE = {
  list: [],
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case LOAD_PROJECTS:
      return { ...state, list: payload.projects };
    default:
      return state;
  }
};
