import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FaCog } from 'react-icons/fa';
import LocalDateConfig from '../config/LocalDateConfig';
import TasksTracking from './TasksTracking';
import WeeklyTasksTracking from './WeeklyTasksTracking';
import { CircularProgress } from '../common';
import Footer from '../weekly/Footer';
import {
  loadCurrentTask,
  loadCurrentWeek,
  loadConfig,
  loadWeeklyDailyTracking,
  loadCEODailyTracking,
  loadProjects,
  loadTrackedTasks,
} from '../../actions';

const TrackScreen = ({ tasksWeek,
  weeklyFocusTracking,
  ceoTracking,
  loadCurrentTaskAction,
  config,
  loadConfigAction,
  loadCurrentWeekAction,
  loadWeeklyDailyTrackingAction,
  loadCEODailyTrackingAction,
  loadProjectsAction,
  loadTrackedTasksAction,
}) => {
  const { weekday, dailyBlocks, saturdayBlocks, sundayBlocks } = config;

  useEffect(() => {
    loadCurrentTaskAction();
    loadConfigAction();
    loadProjectsAction();
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    loadCurrentWeekAction();
    loadWeeklyDailyTrackingAction();
    loadCEODailyTrackingAction();
    loadTrackedTasksAction();
  }, [config]);

  const calcTodayProgress = () => {
    let blocks = dailyBlocks;
    if (weekday === 6) blocks = saturdayBlocks;
    if (weekday === 7) blocks = sundayBlocks;
    const total = (blocks * 7) + (10 * 1) + (3 * 10) + (20); // blocks(7) + weekly focus(1) + weekly topic(10) + ceo(20)

    if (!weeklyFocusTracking[weekday]) {
      return 0;
    }

    const achievedBlocks = tasksWeek.filter((t) => t.weekday === weekday).length;
    const focus = weeklyFocusTracking[weekday].focusCount;
    let topic = 0;
    topic += weeklyFocusTracking[weekday].morning ? 1 : 0;
    topic += weeklyFocusTracking[weekday].noon ? 1 : 0;
    topic += weeklyFocusTracking[weekday].night ? 1 : 0;
    const ceo = !isEmpty(ceoTracking) ? 1 : 0;

    return ((achievedBlocks * 7 + focus * 1 + topic * 10 + ceo * 20) / total) * 100;
  };

  const calcWeeklyBlocks = () => (tasksWeek.length / (dailyBlocks * 5 + saturdayBlocks + sundayBlocks)) * 100;
  const todayProgress = calcTodayProgress();

  return (
    <div>
      <Link to="/tasks" className="tasks-link link">TASKS</Link>
      <Link to="/focus" className="tasks-link link" style={{ marginRight: 30 }}>FOCUS</Link>
      <div>
        <Link to="/config">
          <div style={{ color: 'white', width: 20, height: 20, display: 'inline-block', marginRight: 20 }}>
            <FaCog />
          </div>
        </Link>

        <LocalDateConfig />

        <div style={{ display: 'flex', marginTop: 50 }}>
          <div style={{ flex: 1, margin: 30, textAlign: 'center' }}>
            <TasksTracking />
          </div>

          <div style={{ flex: 1, margin: 30, textAlign: 'center' }}>
            <WeeklyTasksTracking />
          </div>
          <div style={{ flex: 1, margin: 30, textAlign: 'center' }}>
            <div style={{ }}>
              <CircularProgress title="Today Progress" text={`${parseInt(todayProgress, 10)}%`} value={todayProgress} />
              <CircularProgress title="Weekly Blocks" text={`${tasksWeek.length}/56`} value={calcWeeklyBlocks()} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  config: state.app.config,
  currentTask: state.tasks.currentTask,
  tasksWeek: state.tasks.doneWeek || [],
  weeklyFocusTracking: state.weekly.tracking,
  ceoTracking: state.ceo.tracking,
});

const mapDispatchToProps = {
  loadCurrentTaskAction: loadCurrentTask,
  loadConfigAction: loadConfig,
  loadCurrentWeekAction: loadCurrentWeek,
  loadWeeklyDailyTrackingAction: loadWeeklyDailyTracking,
  loadCEODailyTrackingAction: loadCEODailyTracking,
  loadProjectsAction: loadProjects,
  loadTrackedTasksAction: loadTrackedTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackScreen);
