import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TasksList from './TasksList';
import NewTask from './NewTask';
import { CloseButton } from '../common';
import { loadTasks, loadProjects } from '../../actions';

const TasksScreen = ({ tasks, loadTasksAction, loadProjectsAction }) => {
  useEffect(() => {
    loadTasksAction();
    loadProjectsAction();
  }, []);

  return (
    <>
      <CloseButton />
      <NewTask />
      <TasksList tasks={tasks} />
    </>
  );
};

const mapStateToProps = (state) => ({
  tasks: state.tasks.list,
});

const mapDispatchToProps = {
  loadTasksAction: loadTasks,
  loadProjectsAction: loadProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksScreen);
