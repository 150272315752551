import {
  LOAD_CURRENT_WEEK,
  LOAD_WEEKLY_DAILY_TRACKING,
} from '../actions/types';

const INITIAL_STATE = {
  current: {},
  tracking: {},
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case LOAD_CURRENT_WEEK:
      return { ...state, current: payload.current };
    case LOAD_WEEKLY_DAILY_TRACKING:
      return { ...state, tracking: { ...state.tracking, [payload.weekday]: payload.tracking } };
    default:
      return state;
  }
};
