import {
  LOAD_CEO_DAILY_TRACKING,
} from '../actions/types';

const INITIAL_STATE = {
  tracking: {},
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case LOAD_CEO_DAILY_TRACKING:
      return { ...state, tracking: payload.tracking };
    default:
      return state;
  }
};
