import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import TrackScreen from './components/track/TrackScreen';
import ConfigScreen from './components/config/ConfigScreen';
import FocusScreen from './components/timer/FocusModeScreen';
import TasksScreen from './components/tasks/TasksScreen';
import WeeklyFocus from './components/weekly/WeeklyFocus';
import CEOStart from './components/ceo/CEOStart';
import CEOLetter from './components/ceo/CEOLetter';
import CEOYear from './components/ceo/CEOYear';
import CEOQuarter from './components/ceo/CEOQuarter';
import CEOWeek from './components/ceo/CEOWeek';
import './App.css';
import store from './config/ConfigStore';
import configureFirebase from './config/FirebaseConfig';

function App() {
  const [appLoaded, setAppLoaded] = useState(false);
  useEffect(() => {
    configureFirebase();
    setTimeout(() => {
      setAppLoaded(true);
    }, 10);
  }, []);

  return (
    <Provider store={store}>
      {appLoaded && (
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={TrackScreen} />
              <Route exact path="/config" component={ConfigScreen} />
              <Route exact path="/focus" component={FocusScreen} />
              <Route exact path="/tasks" component={TasksScreen} />
              <Route exact path="/currentWeek" component={WeeklyFocus} />
              <Route exact path="/ceo/start" component={CEOStart} />
              <Route exact path="/ceo/letter" component={CEOLetter} />
              <Route exact path="/ceo/year" component={CEOYear} />
              <Route exact path="/ceo/quarter" component={CEOQuarter} />
              <Route exact path="/ceo/week" component={CEOWeek} />
            </Switch>
          </BrowserRouter>
        </div>
      )}
    </Provider>
  );
}

export default App;
