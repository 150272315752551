import React from 'react';
import { Button, CloseButton } from '../common';
import TimelineProgress from './TimelineProgress';

const CEOYear = () => (
  <div style={{ maxWidth: 900, margin: '0 auto' }}>
    <CloseButton />
    <TimelineProgress />
    <h2>2020</h2>
    <ol style={{ fontSize: 21 }}>
      <li>
        Implement a solid lifestyle system (GPD)
        <ul>
          <li>Totally guide my life by the system</li>
          <li>Implement a soft that will backup all my actions</li>
        </ul>
      </li>
      <li>
        Build our home
        <ul>
          <li>Be fully involved in the process</li>
          <li>Review all details during the construction</li>
        </ul>
      </li>
      <li>
        Share my message to the world (Impact Valley)
        <ul>
          <li>Became a speaker/comunicator master</li>
          <li>Seminars in companies, universities/schools, events</li>
          <li>Online course</li>
          <li>Content on social network (writting & videos)</li>
          <li>branding</li>
        </ul>
      </li>
      <li>
        CreatorMobile finish all current projects
      </li>
      <li>
        Startup:
        <ul>
          <li>Launch a business around speaking (tech based)</li>
          <li>App as a service for specific niches</li>
        </ul>
      </li>
    </ol>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <Button linkTo="/ceo/quarter" title="Continue" />
    </div>
  </div>
);

export default CEOYear;
