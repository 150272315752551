import React from 'react';
import { connect } from 'react-redux';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import { updateLocalConfig } from '../../actions';

const LocalDateConfig = ({ currentYear, currentWeek, weekday, updateLocalConfigAction }) => {
  const incrementCurrentDate = (i) => {
    let year = currentYear;
    let week = currentWeek;
    let day = weekday + i;

    if (day === 8) {
      day = 1;
      week += 1;
      if (week === 53) {
        week = 1;
        year += 1;
      }
    }
    if (day === 0) {
      day = 7;
      week -= 1;
      if (week === 0) {
        week = 52;
        year -= 1;
      }
    }

    updateLocalConfigAction({
      currentYear: year,
      currentWeek: week,
      weekday: day,
    });
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <input
        type="number"
        min="2020"
        max="10000"
        value={currentYear}
        onChange={(e) => { updateLocalConfigAction({ currentYear: parseInt(e.target.value, 10) }); }}
      />
      <input
        type="number"
        min="1"
        max="52"
        value={currentWeek}
        onChange={(e) => { updateLocalConfigAction({ currentWeek: parseInt(e.target.value, 10) }); }}
      />
      <input
        type="number"
        min="1"
        max="7"
        value={weekday}
        onChange={(e) => { updateLocalConfigAction({ weekday: parseInt(e.target.value, 10) }); }}
      />
      <div
        onClick={() => incrementCurrentDate(-1)}
        style={{ color: '#fff', display: 'inline-block', margin: '0 20px', fontSize: 24, verticalAlign: 'bottom' }}
      >
        <FiMinusCircle />
      </div>
      <div
        onClick={() => incrementCurrentDate(1)}
        style={{ color: '#fff', display: 'inline-block', fontSize: 24, verticalAlign: 'bottom' }}
      >
        <FiPlusCircle />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentYear: state.app.config.currentYear || 0,
  currentWeek: state.app.config.currentWeek || 0,
  weekday: state.app.config.weekday || 0,
});

const mapDispatchToProps = {
  updateLocalConfigAction: updateLocalConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalDateConfig);
