import firebase from 'firebase';
import {
  LOAD_CURRENT_WEEK,
  LOAD_WEEKLY_DAILY_TRACKING,
} from './types';

export const loadCurrentWeek = () => (
  (dispatch, getStore) => {
    const db = firebase.firestore();
    const { config } = getStore().app;
    db.collection('weeklyFocus').doc(`${config.currentYear}w${config.currentWeek}`).get()
      .then((snapshot) => {
        dispatch({ type: LOAD_CURRENT_WEEK, payload: { current: snapshot.data() } });
      });
  }
);

export const loadWeeklyDailyTracking = () => (
  (dispatch, getStore) => {
    const db = firebase.firestore();
    const { config: { currentYear, currentWeek, weekday } } = getStore().app;
    db.collection('tracking').doc('weeklyFocus').collection(`${currentYear}w${currentWeek}`).doc(weekday.toString())
      .onSnapshot((snapshot) => {
        let tracking = snapshot.data();
        if (!tracking && currentYear && currentWeek) {
          tracking = { morning: false, noon: false, night: false, focusCount: 0, focusTarget: 9 };
          db.collection('tracking').doc('weeklyFocus').collection(`${currentYear}w${currentWeek}`).doc(weekday.toString())
            .set(tracking);
        }
        dispatch({ type: LOAD_WEEKLY_DAILY_TRACKING, payload: { weekday, tracking } });
      });
  }
);

export const trackWeeklyDaily = ({ time }) => (dispatch, getStore) => {
  const db = firebase.firestore();
  const { config: { currentYear, currentWeek, weekday } } = getStore().app;
  db.collection('tracking').doc('weeklyFocus').collection(`${currentYear}w${currentWeek}`).doc(weekday.toString())
    .update({
      [time]: true,
    });
};

export const incrementWeeklyFocusCount = () => (
  (dispatch, getStore) => {
    const db = firebase.firestore();
    const { config: { currentYear, currentWeek, weekday } } = getStore().app;
    db.collection('tracking').doc('weeklyFocus').collection(`${currentYear}w${currentWeek}`).doc(weekday.toString())
      .update({
        focusCount: firebase.firestore.FieldValue.increment(1),
      });
  }
);
