import React from 'react';

const Bullet = ({ active, size, margin }) => (
  <div
    style={{
      background: active ? 'green' : 'gray',
      width: size || 20,
      height: size || 20,
      display: 'inline-block',
      borderRadius: size / 2 || 10,
      margin: margin || 4,
    }}
  />
);

export default Bullet;
