import { combineReducers } from 'redux';
import AppReducers from './AppReducers';
import ProjectReducers from './ProjectReducers';
import TaskReducers from './TaskReducers';
import WeeklyReducers from './WeeklyReducers';
import CEOReducers from './CEOReducers';
import TimerReducers from './TimerReducers';

export default combineReducers({
  projects: ProjectReducers,
  tasks: TaskReducers,
  app: AppReducers,
  weekly: WeeklyReducers,
  ceo: CEOReducers,
  timer: TimerReducers,
});
