import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FaRocket } from 'react-icons/fa';
import { incrementWeeklyFocusCount } from '../../actions';

const Footer = ({ weekday, focus, tracking, ceoTracking, incrementWeeklyFocusCountAction }) => {
  const handleIncrementFocusCount = () => incrementWeeklyFocusCountAction();

  const renderFocusTracking = () => {
    if (tracking[weekday]) {
      const bullets = [];
      for (let i = 0; i < tracking[weekday].focusCount; i++) {
        bullets.push(<div key={i} style={{ width: 8, height: 8, background: 'green', display: 'inline-block', borderRadius: 4, margin: 1 }} />);
      }
      for (let i = tracking[weekday].focusCount; i < tracking[weekday].focusTarget; i++) {
        bullets.push(<div key={i+1000} style={{ width: 8, height: 8, background: 'gray', display: 'inline-block', borderRadius: 4, margin: 1 }} />);
      }

      return (
        <div
          onClick={handleIncrementFocusCount}
          style={{ display: 'inline-block', marginRight: 20, cursor: 'pointer' }}
        >
          {bullets}
        </div>
      );
    }
    return null;
  };

  const renderDailyTracking = () => {
    if (tracking[weekday]) {
      const bullet = (active) => (
        <div style={{ background: active ? 'green' : 'gray', width: 20, height: 20, display: 'inline-block', borderRadius: 10, margin: 4 }} />
      );

      const bullets = [];
      bullets.push(bullet(tracking[weekday].morning));
      bullets.push(bullet(tracking[weekday].noon));
      bullets.push(bullet(tracking[weekday].night));
      return (
        <Link to="/currentWeek" style={{ float: 'right' }}>
          {bullets}
        </Link>
      );
    }
    return null;
  };

  const renderCEOTracking = () => (
    <Link to="/ceo/start" style={{ float: 'right' }}>
      <div style={{ color: isEmpty(ceoTracking) ? 'gray' : 'green', width: 20, height: 20, display: 'inline-block', paddingTop: 7, marginRight: 50, marginLeft: 10 }}>
        <FaRocket />
      </div>
    </Link>
  );

  return (
    <div className="footer">
      {renderFocusTracking()}
      {focus}
      {renderCEOTracking()}
      {renderDailyTracking()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  weekday: state.app.config.weekday,
  focus: state.weekly.current ? state.weekly.current.focus : '',
  tracking: state.weekly.tracking,
  ceoTracking: state.ceo.tracking,
});

const mapDispatchToProps = {
  incrementWeeklyFocusCountAction: incrementWeeklyFocusCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
